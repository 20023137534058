<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <div class="row">
          <div class="col-12">
            <h3 class="mb-0">
              Complete Sign Up
            </h3>
            <p>
              Complete your profile
            </p>
          </div>
          <ValidationObserver ref="quickProfileForm">
            <form class="mt-4">
              <div v-bind:class = "(operatingsystem == 'web') ? 'gide_scroll_box row m-0' : 'gide_box row m-0'">
                <div class="form-group col-12">
                  <label for="yourFullName">Your full name</label>
                  <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                    <input v-if="!userData.login_type || userData.login_type == 'email'" v-model="userName" type="text" class="form-control mb-0" style="color:var(--iq-dark)" placeholder="Your Full Name">
                    <input v-else v-model="userName" type="text" class="form-control mb-0" placeholder="Your Full Name" disabled>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- Dont Show for Google & Apple Login -->
                <div class="form-group col-12" v-if="!userData.login_type || userData.login_type == 'email'">
                  <label for="exampleInputPassword1">Password</label>
                  <ValidationProvider name="Password" rules="required|min:6" v-slot="{ errors }">
                    <input v-model="userPassword" type="password" class="form-control mb-0" id="exampleInputPassword1" placeholder="Password">
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-6 mb-3">
                  <label for="validation_country">Country Code</label>
                    <div class="flex-row">
                      <ValidationProvider
                        name="select one"
                        rules="required"
                        v-slot="{ errors }">
                          <select v-model="countryCodes" class="form-control w-100" id="validation_country">
                          <template class="w-100">
                            <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                              {{country.country_name}} (+ {{country.country_phone_code}})
                            </option>
                          </template>
                        </select>
                        <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-8 col-lg-6 mb-3">
                  <ValidationProvider
                    name="Enter contact number"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="validation_stu_contact_no">
                      Contact Number
                    </label>
                    <input v-model="userMobile" type="tel" class="form-control" id="validation_stu_contact_no" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div>

                <!-- <div class="form-group col-12">
                  <label for="source">NOT SO SECRET CODE</label>
                  <input v-model="source" type="text" class="form-control mb-0" placeholder="If you have a code add it here or leave it blank">
                </div> -->

                <div class="form-group col-12" v-if="!userData.user_role || (userData.user_role && userData.user_role == 'USERROLE11114')">
                  <div id="studentRole" class="form-group col-12">
                    <div class="form-group">
                      <label for="Choose Your School">Choose Your {{cvSchoolLabel}}</label>
                      <multiselect
                        v-model="studentSchool"
                        :options="cvSchoolOptions"
                        :placeholder="cvSelectBoxText +' '+ cvSchoolLabel"
                        track-by="org_id"
                        ref="multiselectschool"
                        @search-change="SearchSchoolList($event)"
                        :custom-label="schNameWithCityCountry">
                        <span slot="noResult">No school found<br> (Consider changing the search query) or
                          <br><hr>
                          <span @click="showAddSchool()" class="schoolAdd">
                            <i title='Add School' class="fa-solid fa-circle-plus"></i> Click here to add school</span>
                        </span>
                      </multiselect>
                      <div v-show="showErrBox1" class="text-danger">
                        {{errMsg}}
                      </div>
                    </div>

                    <template v-if="addSchoolShow">
                      <ValidationObserver ref="schoolForm">
                        <div class="form-group row">
                          <div class="col-md-12 col-lg-12 col-sm-12" v-for="(item, index) in cvSchoolForm" :key="index">
                            <label :for="item.label">{{item.label}}</label>
                            <ValidationProvider :name="item.label" rules="required|min:2" v-slot="{ errors }">
                              <input v-model="schoolForm[item.key]" :type="item.type" class="form-control mb-0" :id="item.label">
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </ValidationObserver>
                      <div class="form-group" style="margin-bottom:50px">
                        <button class="btn btn-primary" type="button" @click="addSchool" style="float: right;">
                          Add School
                        </button>
                        <b-button type="button" @click="addSchoolShow = false" style="float: right; margin-right:10px">
                          Cancel
                        </b-button>
                      </div>
                    </template>

                    <div class="form-group">
                      <label for="Class">{{cvBoardLabel}}</label>
                      <multiselect
                        v-model="studentBoard"
                        :options="cvBoardOptions"
                        :placeholder="cvSelectBoxText +' '+ cvBoardLabel"
                        label="board_name"
                        track-by="board_name">
                      </multiselect>
                      <div v-show="showErrBox2" class="text-danger">
                        {{errMsg}}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="Class">{{cvClassLabel}}</label>
                      <multiselect
                        v-model="studentClass"
                        :options="cvClassOptions"
                        :placeholder="cvSelectBoxText +' '+ cvClassLabel"
                        label="class_name"
                        track-by="class_name">
                      </multiselect>
                      <div v-show="showErrBox2" class="text-danger">
                        {{errMsg}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block col-12 w-100">
                  <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="tnc">
                    <label class="custom-control-label" for="customCheck1">I have read and accept the <a href="/content/Tnc" target="_blank">Terms of Use</a>
                    </label>
                    <div v-show="showTncErrBox" class="text-danger">
                      {{errMsg}}
                    </div>
                  </div>
                </div><!-- d-inline-block w-100 -->
                <div class="gide_button_fix">
                  <div class="SubmitBtn col-12">
                    <button type="button" class="btn btn-primary float-right" @click="userProfileComplete">
                      Submit
                    </button>
                  </div>
                </div>
              </div><!-- gide_scroll_box -->
            </form>
            <div class="sign-info" style="margin-top: 16px;">
              <span class="dark-color d-inline-block line-height-2">Go back to <router-link :to="{ name: 'auth.login'}">Sign In</router-link>
              </span>
              <ul class="iq-social-media mt-4">
                <li>
                  <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                </li>
                <li>
                  <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                </li>
              </ul>
            </div>
            <div class="staticContent">
              <a href="/content/Tnc" target="_blank">Terms and Conditions</a>  | <a href="/content/PrivacyPolicy" target="_blank">Privacy Policy</a>
            </div>
          </ValidationObserver>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style lang="scss" scoped>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  .staticContent {
    margin-top: 20px;
    text-align: center;
    display:inline-flex;
    width: 100%;
    a{
      width: 100%;
    }
  }
  .SubmitBtn {
    width: 100%;
    padding-top: 13px;
    text-align: center;
  }
  .schoolAdd {
    color: var(--iq-primary);
    i {
      margin-right: 8px;
    }
  }
  .sign-info{
    border: 0px;
    padding:0px;
    margin:30px 0px;
  }
  @media screen and (min-width:576px){
    .gide_scroll_box{
      overflow: auto;
      height: calc(100vh - 320px);
      max-height: 550px;
      .form-group{
        width: 100%;
      }
    }
    .gide_box{
      overflow: auto;
      height: calc(100vh - 320px);
      max-height: 550px;
      .form-group{
        width: 100%;
      }
    }
  }
  .gide_button_fix{
    float: right;
    width: 100%;
  }
  .gide_scroll_box{
    .form-group{
      width: 100%;
    }
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { User } from "../FackApi/api/user.js"
import AutoLogin from "../Utils/autoLogin.js"
import { Organisations } from "../FackApi/api/organisation.js"
import ApiResponse from "../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import config from "../Utils/config.js"
import { Countries } from "../FackApi/api/country.js"

export default {
  name: "Register",
  components: {
    Multiselect
  },
  data: () => ({
    cvSelectBoxText: "Start typing ",
    cvSchoolLabel: "School",
    cvBoardLabel: "Board",
    cvClassLabel: "Class",
    errMsg: "",
    cvSchoolLocality: " School Locality/Area/Sector",
    showTncErrBox: false,
    showErrBox1: false,
    showErrBox2: false,
    cvShowStudentInput: false,
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Registration",
    studentSchool: "",
    cvSchoolOptions: [],
    studentBoard: "",
    studentClass: "",
    cvClassOptions: config.ClassList,
    cvBoardOptions: config.BoardObjList,
    cvUniversityOptions: [],
    educatorSchool: "",
    userName: "",
    countryCodes: null,
    cvCountryOptions: [],
    userMobile: "",
    userRole: { user_role_id: "USERROLE11114" },
    userPassword: "",
    source: "",
    orgId: "",
    tnc: false,
    showPasswordField: false,
    cvUserRoleLabel: "You are a",
    operatingsystem: window.__DEVICE_OS__,
    schoolForm: {
      org_name: "",
      org_locality: "",
      org_desc: "",
      org_board_offered: "",
      org_city: "",
      org_state: "",
      org_country: "",
      org_address: "",
      org_contact: "",
      org_website: ""
    },
    cvSchoolForm: [
      { key: "org_name", type: "text", label: "School Name" },
      { key: "org_locality", type: "text", label: "School Locality" },
      { key: "org_city", type: "text", label: "School City" },
      { key: "org_state", type: "text", label: "School State" },
      { key: "org_country", type: "text", label: "School Country" }
    ],
    addSchoolShow: false,
    disableUserRole: false,
    schLimit: 20,
    cvSchoolOptionsBackup: [],
    schoolCheckRegx: / school|college|institute|academy|mandir|high|centre|Vidyapeeth|convent|vihar|house|vidhyapit|devshala|niketan|vidhyapith|Vidyapith|institution|Shiksha|bhavan|society|secondary|Vidhyalaya|peeth|guru|dps|Secondary|kendra|kendriya|senior|ashram|campus|sch|public|pub|the|Vidyalaya|sansthan/gmi
  }),
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    sourceData () {
      return this.$store.getters["SourceTracker/getUserSource"]
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  beforeMount () {
    if (this.userData) {
      this.userEmail = this.userData.user_email
      this.userName = this.userData.user_name
      this.show_user_email = true
    }
    else {
      this.$router.push("/login")
    }
  },
  mounted () {
    history.pushState(null, null, location.href)
    window.onpopstate = function () {
      history.go(1)
    }

    // if sourceData is available then store in source else take user defined value to store in source
    if (this.sourceData) {
      this.source = this.sourceData
    }

    // if orgId is available then store in orgId so as to get the user registered under an organisation
    if (this.saasOrgData && this.saasOrgData.org_id) {
      this.orgId = this.saasOrgData.org_id
    }

    this.loadRequiredData()
  },
  methods: {
    /*eslint-disable */
    schNameWithCityCountry ({ org_name, org_city, org_country }) {
      return `${org_name}${org_city ? ', '+org_city : ''}${org_country ? ', '+org_country : ''}`
    },
    /**
     * showAddSchool
     */
    showAddSchool () {
      this.addSchoolShow = true
      this.$refs.multiselectschool.toggle()
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.countryDistinctList()
      this.schoolList()
    },
    /**
     * schoolList
     */
    async schoolList () {
      const filter = {
        limit: this.schLimit,
        type: ["SCH"]
      }

      let schoolResp = await Organisations.organisationList(this, filter, 1)
      if (schoolResp.resp_status) {
        this.cvSchoolOptions = Object.keys(schoolResp.resp_data.data).map((key)=> schoolResp.resp_data.data[key])
        this.cvSchoolOptionsBackup = [ ...this.cvSchoolOptions ]
      }
    },
    /**
     * SearchSchoolList
     */
    async SearchSchoolList (searchString) {
      if (!searchString || searchString.trim().length < 1) {
        this.cvSchoolOptions = [ ...this.cvSchoolOptionsBackup ]
        return
      }

      if (searchString.length < 3) {
        return
      }

      if (searchString.toLowerCase() === 'school' || searchString.toLowerCase() === 'schoo' || searchString.toLowerCase() === 'schoo' || searchString.toLowerCase() === 'sch') {
        this.toastMsg = 'Please enter your school name.'
        this.toastVariant = 'danger'
        this.showToast = true
        return
      }

      const filter = {
        type: ["SCH"],
        search: searchString.trim()
      }

      let schoolResp = await Organisations.organisationListSearch(this, filter)
      if (schoolResp.resp_status) {
        this.cvSchoolOptions = schoolResp.resp_data.data
      }
    },
    /**
     * universityList
     */
    async universityList (searchString = null) {
      let filter = {}

      if (searchString && searchString.trim().length >= 2) {
        filter.search = searchString.trim()
      }

      filter.limit = 24
      filter.type = ["SCH"] // Get Only Schools
      filter.fields = [ "org_name", "org_city", "org_country", "org_admin_email" ]

      let universityResp = await Organisations.organisationListSearch(this, filter)
      if (universityResp.resp_status) {
        this.cvUniversityOptions = Object.values(universityResp.resp_data.data)
      }
    },
    /**
    /**
     * userProfileComplete
     */
    async userProfileComplete () {
      let valid = await this.$refs.quickProfileForm.validate()
      if (!valid) {
        return
      }

      let validRoleInput = this.validateRoleInput()
      if (!validRoleInput) {
        return
      }

      if (!this.tnc) {
        this.errMsg = 'Tnc is required'
        this.showTncErrBox = true
        return false
      }

      let userObj = {
        'user_id': this.userData.user_id,
        'user_email': this.userData.user_email,
        'user_mobile': `+${this.countryCodes.country_phone_code}` + this.userMobile,
        'user_name': this.userName,
        'user_password': this.userPassword ? this.userPassword : false,
        'user_first_time_login': this.userData.user_first_time_login,
        'user_role': this.userData.user_role ? this.userData.user_role : this.userRole.user_role_id, // If role has already been allocated during the manage/invite user by the admin of the org then use that
        'student_school': this.studentSchool ? this.studentSchool.org_id : false,
        'student_school_name': this.studentSchool ? this.studentSchool.org_name : false,
        'user_city': this.studentSchool ? this.studentSchool.org_city : false,
        'user_country': this.countryCodes.id, // //country id in the country table
        'user_country_name': this.studentSchool ? this.studentSchool.org_city : false,
        'student_board': this.studentBoard ? this.studentBoard.board_name : false,
        'student_class': this.studentClass ? this.studentClass.class_name : false,
        'tnc': this.tnc,
        'module_obj_id': null,
        'module_type': null,
        'params': null,
        'device_id': window.__DEVICE_OS__,
        'fcm_push_token': "GIDE0000",
        'source' : this.source,
        'org_id': this.orgId
      }

      if (userObj.user_role === 'USERROLE11114') {
        if(!userObj.student_school) {
          // looks like student has not set the school info
          this.errMsg = 'School Info Missing'
          this.showErrBox1 = true
          return false
        }

        //student
        const params = {
          org_id: userObj.student_school,
          board_name: userObj.student_board,
          class_name: userObj.student_class
        }

        userObj.module_obj_id = params.org_id
        userObj.module_type = "SCH"
        userObj.params = JSON.stringify(params)        
      }

      let userProfileCompleteResp = await User.userProfileComplete(this, userObj)
      await ApiResponse.responseMessageDisplay(this, userProfileCompleteResp)
      if (!userProfileCompleteResp.resp_status) {
        this.toastVariant = 'danger'
      }
      else {
        /* check if the user is logging in for the first time, if yes and
           the user_role is USERROLE11116(org_admin) take him to the organisation edit page 
        */

        if (this.userData.user_first_time_login == 1 && this.userData.user_role == "USERROLE11116") {
          let org = Object.keys(userProfileCompleteResp.resp_data.roles)[0]
          org = userProfileCompleteResp.resp_data.roles[org]

          if (org.udr_name == "Owner") {
            // set the deeplink to the edit page only if the user is owner of the org
            const deepLink = `/org_edit/ORG/${org.module_obj_id}`
            await this.$store.dispatch("DeepLink/setDeepLinkAction", deepLink)
          }
        }
        await AutoLogin.setUserLoginProfile(this, userProfileCompleteResp)
      }
    },
    /**
     * validateRoleInput
     */
    validateRoleInput () {
      if (this.userData.user_role && this.userData.user_role != "USERROLE11114") {
        // It looks like the user exists and has already been assigned a role. Most probably via the manage/Invite User Section in the organisation
        // To be checked only for UNIV, ORG roles not for student or parent
        return true
      }      

      if (this.userRole.user_role_id === 'USERROLE11114') {
        if (!this.studentSchool) {
          this.errMsg = 'Please select a school'
          this.showErrBox1 = true
          return false
        }
        else if (!this.studentClass) {
          this.errMsg = 'Please select a Class'
          this.showErrBox2 = true
          return false
        }
      }

      return true
    },
    /**
     * addSchool
    */
    async addSchool () {
      let valid = await this.$refs.schoolForm.validate()
      if (!valid) {
        return
      }

      if (!this.schoolForm.org_name.match(this.schoolCheckRegx)) {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Please enter valid school name"
        return
      }

      this.schoolForm.type = "SCH"
      this.schoolForm.org_admin_email = process.env.VUE_APP_SUPPORT_EMAIL
      this.schoolForm.deleted = 3
      this.schoolForm.user_role = "USERROLE11114" // user is creating the organisation 
      this.schoolForm.org_name += ", " + this.schoolForm.org_locality 

      const schoolAddResp = await Organisations.organisationAdd(this, this.schoolForm);
      if (schoolAddResp.resp_status) {
        this.cvSchoolOptions.unshift(schoolAddResp.resp_data)
        this.studentSchool = schoolAddResp.resp_data
        this.addSchoolShow = false
      }

      ApiResponse.responseMessageDisplay(this, schoolAddResp, "Add School")
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }

        this.cvCountryOptions = countryDistinctListResp.resp_data.data
        if (this.userData && this.userData.country_id) {
          const findIndex = this.cvCountryOptions.findIndex(ele => ele.id === this.userData.country_id)
          if (findIndex >= 0) {
            this.countryCodes = this.cvCountryOptions[findIndex]
            if (this.userData.user_mobile) {
              let regx = new RegExp(this.countryCodes.country_phone_code, 'gm')
              this.userMobile = this.userData.user_mobile.replace(regx, '')
            }
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },    
  }
}
</script>
